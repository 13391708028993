import React, { Ref, useContext, useRef, useState } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { SiteContext } from 'src/context/site-context';
import { flex, zIndexes } from 'src/styling/constants';
import mq, { mqReducedMotion } from 'src/styling/media-queries';
import Map from '../icons/map';
import Location from '../icons/location';
import Cross from '../icons/cross';
import Search from '../icons/search-icon';
import useWindowSize from 'src/custom-hooks/use-window-size';
import useOutsideClick from 'src/custom-hooks/use-outside-click';
import { getAbsoluteClubPagePath, getClubPagePath } from 'src/helpers/navigation-helper';
import { WebsiteLink } from './website-link';
import { Styling } from 'src/context/styling';
import { useClubSearch } from 'src/custom-hooks/use-club-search-form';
import { CLUBS_LINK } from 'src/enums/clubs';
import { keyframes } from '@emotion/react';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import { Link } from 'gatsby';
import { ClubInformation } from 'src/enums/club-information';

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
`;

export const createAnimationStyling: (
  isDropdownShown: boolean,
  suggestionsLength: number
) => { [key: string]: CSSInterpolation } = (isDropdownShown, suggestionsLength) => {
  const baseTransition =
    'height .5s cubic-bezier(.02,.01,.47,1),padding-top .2s cubic-bezier(.02,.01,.47,1),opacity .15s cubic-bezier(.02,.01,.47,1)';

  return {
    dropdownAnimation: mqReducedMotion({
      height: `${isDropdownShown ? Math.min((suggestionsLength + 1) * 3.75 + 1, 31) : '0'}rem`,
      opacity: isDropdownShown ? '1' : '0',
      paddingTop: [0, isDropdownShown ? '0' : '3.75rem'],
      transition: [
        isDropdownShown ? baseTransition : baseTransition + ', visibility 0.15s',
        'none',
      ],
    }),
    dropdownItemAnimation: mqReducedMotion({
      animation: [isDropdownShown ? fadeInUp : 'none', 'none'],
      animationFillMode: 'both',
      animationDelay: '0.2s',
      animationDuration: '.5s',
    }),
    closeIconAnimation: mqReducedMotion({
      opacity: isDropdownShown ? '1' : '0',
      transition: ['opacity 0.5s', 'none'],
    }),
  };
};

const createStyling: (styling: Styling) => { [key: string]: CSSInterpolation } = (styling) => ({
  container: {
    fontSize: '1.1875rem',
    color: styling.colors.darkGreen,
    maxWidth: '27rem',
    width: '100%',
    position: 'relative',
  },
  wrapper: [
    flex.row,
    mq({
      width: ['calc(100% - 2.5rem)', '100%'],
      gap: '0.75rem',
      margin: '0 auto',
      position: 'relative',
    }),
  ],
  dropdown: mq({
    'position': ['static', 'absolute'],
    'top': '3.4375rem',
    'marginTop': ['1rem', '0'],
    'width': '100%',
    'maxHeight': ['13rem', '31rem'],
    'overflowY': 'auto',
    'borderRadius': '0.375rem',
    'borderTopLeftRadius': '0',
    'borderTopRightRadius': '0',
    'border': ['none', `1px solid ${styling.colors.darkGreen}`],
    'backgroundColor': ['none', 'white'],
    'textAlign': 'left',
    'zIndex': zIndexes.dropdown,
    '> a:nth-of-type(even)': {
      backgroundColor: styling.colors.davidLloydGray,
    },
  }),
  dropdownItemHover: {
    '&:hover': {
      'color': styling.colors.camelot,
      'textDecoration': 'underline',
      '> svg > path': {
        fill: styling.colors.camelot,
      },
    },
  },
  closeButton: [
    flex.rowCentered,
    {
      cursor: 'pointer',
      position: 'absolute',
      right: '0.75rem',
      height: '100%',
    },
  ],
  viewMapButton: mq({
    flexWrap: 'wrap',
    position: 'sticky',
    top: ['-2px', '0'],
    background: [styling.colors.cream, 'white'],
    display: 'flex',
    gap: '1rem',
    padding: ['1rem 1.5rem', '1.5rem 1.5rem 0 1.5rem'],
    marginBottom: '0.5rem',
    fontSize: ['1.1875rem', 'unset'],
    lineHeight: ['145%', 'unset'],
    zIndex: '1',
  }),
  searchIcon: [flex.rowCentered, { position: 'absolute', left: '1.5rem', height: '100%' }],
  dropdownItem: {
    cursor: 'pointer',
    gap: '1rem',
    padding: '1rem 1.5rem',
    fontSize: '1.1875rem',
    lineHeight: '145%',
    minHeight: '3.75rem',
  },
});

type ClubSearchLinkProps = {
  styles: CSSInterpolation;
  club: ClubInformation;
  onClick?: () => void;
};

export const ClubSearchLink = ({ styles, club, onClick }: ClubSearchLinkProps) => {
  const { language, buildType } = useContext(SiteContext);

  return club.buildType === buildType ? (
    <Link to={getClubPagePath('', club.siteId, language)} css={styles} onClick={onClick}>
      <Location />
      {club.clubName}
    </Link>
  ) : (
    <a href={getAbsoluteClubPagePath('', club.siteId, language)} css={styles} onClick={onClick}>
      <Location />
      {club.clubName}
    </a>
  );
};

type ClubSearchInputProps = {
  inputPlaceholder: string;
  pinnedSearchOptionText: string;
  inputRef?: Ref<HTMLInputElement>;
  isNavigation?: boolean;
  onFinish?: () => void;
};

export const ClubSearchInput = ({
  inputPlaceholder,
  pinnedSearchOptionText,
  inputRef,
  isNavigation,
  onFinish,
}: ClubSearchInputProps) => {
  const containerRef = useRef(null);
  const { styling, pageLevel } = useContext(SiteContext);
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isTabletOrSmaller } = useWindowSize();
  const filteredClubs = useClubSearch(searchValue);
  const componentStyling = createStyling(styling);
  const animationStyling = createAnimationStyling(isDropdownOpen, filteredClubs.length);

  useOutsideClick(containerRef, () => {
    if (!isTabletOrSmaller) {
      setIsDropdownOpen(false);
    }
  });

  const closeIcon = (
    <div
      tabIndex={0}
      onClick={() => {
        setIsDropdownOpen(false);
        setSearchValue('');
      }}
      css={[animationStyling.closeIconAnimation, componentStyling.closeButton]}
    >
      <Cross />
    </div>
  );

  const handleClickViewMap = () => {
    onFinish?.();
    return amplitudeTrackEvent(
      `Click to View Map in the "Find a club" - ${isNavigation ? 'Navigation' : 'On The Page'}`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );
  };

  const handleInputFocus = () =>
    amplitudeTrackEvent(
      `Focus to Club List in the "Find a club" - ${isNavigation ? 'Navigation' : 'On The Page'}`,
      {
        custom_page: window.location.origin,
        custom_level: pageLevel,
      }
    );

  const viewMapLink = (
    <WebsiteLink
      to={CLUBS_LINK}
      css={[
        flex.rowCentered,
        animationStyling.dropdownItemAnimation,
        componentStyling.viewMapButton,
        componentStyling.dropdownItemHover,
      ]}
      handleClick={handleClickViewMap}
    >
      <>
        <Map />
        {pinnedSearchOptionText}

        {!isTabletOrSmaller && (
          <span
            css={{
              height: filteredClubs.length > 0 ? '1px' : '0',
              backgroundColor: styling.colors.brightGrey,
              width: '100%',
            }}
          />
        )}
      </>
    </WebsiteLink>
  );

  return (
    <div ref={containerRef} css={componentStyling.container}>
      <div css={componentStyling.wrapper}>
        {closeIcon}
        <div css={componentStyling.searchIcon}>
          <Search fill={styling.colors.capeCod} />
        </div>
        <input
          ref={inputRef}
          value={searchValue}
          onChange={(e) => {
            const { value } = e.target;
            setIsDropdownOpen(value.trimStart().length > 0);
            setSearchValue(value.trimStart());
          }}
          onFocus={() => {
            setIsDropdownOpen(searchValue.length > 0);
            handleInputFocus();
          }}
          name="club-search"
          autoComplete="off"
          placeholder={inputPlaceholder}
          css={[
            styling.redesignForms.input,
            { paddingLeft: '3.6875rem' },
            isDropdownOpen && !isTabletOrSmaller
              ? {
                  borderBottom: '1px solid transparent',
                  borderBottomLeftRadius: '0',
                  borderBottomRightRadius: '0',
                }
              : {},
          ]}
        />
      </div>
      <div
        css={[
          animationStyling.dropdownAnimation,
          componentStyling.dropdown,
          { visibility: isDropdownOpen ? 'visible' : 'hidden' },
        ]}
      >
        {viewMapLink}
        {filteredClubs.map((club) => (
          <ClubSearchLink
            key={`${club.siteId}-${searchValue}`}
            styles={[
              flex.rowCentered,
              animationStyling.dropdownItemAnimation,
              componentStyling.dropdownItem,
              componentStyling.dropdownItemHover,
            ]}
            club={club}
            onClick={onFinish}
          />
        ))}
      </div>
    </div>
  );
};
